@tailwind base;
@tailwind components;
@tailwind utilities;


html{
    font-size: 100%;
    line-height: 1.3;
}

body{
    font-family: 'Inter Var', sans-serif;
    font-size: 14px;
}

.dark-item-bg:hover,
.dark-item-bg.selected{
    background: #EBECF0;
    color: #42526E;
}

.dark-side-menu{
    background: #fafbfc !important;
}

.mr-350{
    margin-right: 450px !important;
}
.mr-460{
    @apply mr-0 lg:mr-[460px];

}

.-z-10{
    @apply z-40 md:z-40 lg:z-0;
}

.mb-025{
    margin-bottom: 0.25rem !important;
}

.z-3{
    z-index: 3;
}

.rounded {
    border-radius: .3rem;
}

.rounded-sm {
    border-radius: .25rem;
}

.rounded-lg {
    border-radius: .4rem;
}

.form-control{
    @apply border-gray-200 rounded-md leading-7 text-sm focus:ring-0 focus:border-gray-400;
}

#checkname, #checkba{
    @apply border-gray-200 rounded-md leading-7 text-sm focus:ring-0 focus:border-gray-400;
}

.cash-info p {
    @apply leading-6;
}

.t-card{
    @apply mx-auto mb-6 mt-0 rounded-lg shadow-lg border relative;
    @apply max-w-lg md:max-w-xl lg:max-w-3xl;
    z-index: 2;
}

.t-card .text-5xl,
.slide-over .text-5xl{
    font-size: 2.5rem;
}

.t-card-body{
    padding-left: 40px;
    padding-right: 40px;
}

.text-xs{
    font-size: 0.75rem;
    line-height: 1rem;
}

.bb-0{
    border: 0;
    border-color: transparent;
    border-width: 0;
}

.bb{
    border-bottom: 1px solid #ddd;
}
.font-18{
    font-size: 18px;
}

.card-box a{
    @apply transition-all;
}
.card-box .selected{
    border-color: var(--bs-primary) !important;
    color: var(--bs-primary);
    border-width: 2px !important;
}

.t-card-body .badge{
    height: 30px;
    width: 30px;
    line-height: 1.1rem;
}

.payment-method-container{
    flex-flow:row;
    height:100%;
    width:42rem;
    overflow-x: auto;
}

.payment-method-container a{
    min-width: 125px;
}

.rbt-input-main{
    border: 0;
    height: 64px;
    margin-left: 0;
    z-index: 21;
    background: transparent;
    @apply pl-[44px] lg:pl-[65px];
}

.search-field-icon{
    z-index: 20;
    top: 0;
    @apply left-[10px] lg:left-[35px];
}

.rbt-input-main:focus{
    background: transparent;
}

.rbt-aux{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
}

.rbt-menu.show{
    border: 0;
    border-radius: 0;
    @apply shadow-2xl !w-[300px] lg:!w-[500px];
}

.rbt-menu .dropdown-item:not(.disabled){
    padding: 0;
    @apply transition-all;
}

.rbt-menu .dropdown-item:not(.disabled):hover p {
    @apply text-gray-700 font-bold !important;
}

.rbt-menu .dropdown-item:not(.disabled):hover div,
.rbt-menu .dropdown-item:not(.disabled):hover em {
    @apply text-gray-700 !important;
}

.link-hover:hover{
    text-decoration: underline;
}

.badge {
    @apply rounded;
}

.badge-lg {
    @apply rounded-lg;
}