// Import functions
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/mixins';
@import 'user-functions';

a:not(.dropdown-item) {
    @include hover() {
        filter: brightness(93%);
    }
}

a.badge-primary:focus,
a.badge-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(#{v(primary-rgb)}, .5);
}

.text-primary,
.btn-soft-primary,
.accordion .card>.card-header[aria-expanded="true"]>*,
.accordion .card>.card-header:hover>*,
.accordion .card>.card-header .accordion-icon,
.alert-primary,
.badge-soft-primary {
    color: v(primary) !important;
}

.bg-primary,
.bg-green-500,
.badge-primary {
    background-color: v(primary) !important;
}

.bg-soft-green-5,
.alert-primary,
.badge-soft-primary {
    background-color: rgba(#{v(primary-rgb)}, .05) !important;
}

.alert-primary {
    border-color: rgba(#{v(primary-rgb)}, .05) !important;
}

.bg-green-200 {
    background-color: rgba(#{v(primary-rgb)}, .7) !important;
}

.border-primary,
.border-green-500 {
    border-color: v(primary) !important;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
    background-color: v(primary);
    border-color: v(primary);

    @include hover-focus-active() {
        background-color: v(primary) !important;
        border-color: v(primary) !important;
        filter: brightness(93%);
    }
}

.btn-outline-primary {
    color: v(primary);
    border-color: v(primary);

    @include hover-focus-active() {
        color: #fff;
        background-color: v(primary) !important;
        border-color: v(primary) !important;
    }
}

.btn-soft-primary {
    background-color: rgba(#{v(primary-rgb)}, .1);

    @include hover-focus-active() {
        background-color: rgba(#{v(primary-rgb)}, .12);
    }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: v(primary) !important;
    border-bottom-color: v(primary) !important;
}

.form-control:focus,
.custom-select:focus,
custom-file-input:focus~.custom-file-label,
.form-file-group .custom-file-box:active,
.custom-control-primary .custom-control-input:focus:not(:checked)~.custom-control-label::before,
.input-group-merge>.form-control:focus+[class*="input-group"]>.input-group-text,
.input-group-merge>.custom-select:focus+[class*="input-group"]>.input-group-text,
.custom-control-input:focus:not(:checked)~.custom-control-label::before,
.dropzone .dz-message:active,
.dropzone.dz-drag-hover .dz-message,
.select2-search--dropdown .select2-search__field:focus,
.braintree-sheet .braintree-form__field-group .braintree-form__field .braintree-form__hosted-field:focus,
.flex-microform-focused,
.StripeElement--focus {
    border-color: rgba(#{v(primary-rgb)}, .4);
}

.placeholder-green-500::placeholder {
    color: v(primary) !important;
}

.placeholder-focus-green-100:focus::placeholder {
    color: rgba(#{v(primary-rgb)}, .3) !important;
}

.border-hover-green-500 {
    @include hover() {
        border-color: v(primary) !important;
    }
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: v(primary);
    border-color: v(primary);
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    background-color: rgba(#{v(primary-rgb)}, .5);
    border-color: rgba(#{v(primary-rgb)}, .5);
}

.form-check-input{
    &:focus{
        outline-color: v(primary) !important;
    }
    &:checked {
        background-color: v(primary) !important;
        border-color: v(primary) !important;
    }
}