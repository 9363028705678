//
// Mixins
//

@function v($var) {
    @return var(--bs-#{$var});
}

@function to-rgb($value) {
    @return red($value),
    green($value),
    blue($value);
}

@function map-loop($map, $func, $args...) {
    $_map: ();

    @each $key,
    $value in $map {
        // allow to pass the $key and $value of the map as an function argument
        $_args: ();

        @each $arg in $args {
            $_args: append($_args, if($arg=="$key", $key, if($arg=="$value", $value, $arg)));
        }

        $_map: map-merge($_map, ($key: call(get-function($func), $_args...)));
    }

    @return $_map;
}

// Return opaque color
// opaque(#fff, rgba(0, 0, 0, .5)) => #808080
@function opaque($background, $foreground) {
    @return mix(rgba($foreground, 1), $background, opacity($foreground) * 100);
}

// scss-docs-start color-functions
// Tint a color: mix a color with white
@function tint-color($color, $weight) {
    @return mix(white, $color, $weight);
}

// Shade a color: mix a color with black
@function shade-color($color, $weight) {
    @return mix(black, $color, $weight);
}

@function shift-color($color, $weight) {
    @return if($weight > 0, shade-color($color, $weight), tint-color($color, -$weight));
}

//
// Hover Mixins
//

@mixin focus() {
    &:focus {
        @content;
    }
}

@mixin active() {
    &:active {
        @content;
    }
}

@mixin hover-active() {

    &:hover,
    &:active {
        @content;
    }
}

@mixin focus-active() {

    &:focus,
    &:active {
        @content;
    }
}

@mixin hover() {
    &:hover {
        @content;
    }
}

@mixin hover-focus() {

    &:hover,
    &:focus {
        @content;
    }
}

@mixin plain-hover-focus() {

    &,
    &:hover,
    &:focus {
        @content;
    }
}

@mixin hover-focus-active() {

    &:hover,
    &:focus,
    &:active {
        @content;
    }
}

//
// Color Mixins
//

@function tint($color, $level) {
    @return mix($white, $color, $level * $theme-color-interval);
}

@function shade($color, $level) {
    @return mix($black, $color, $level * $theme-color-interval);
}