$font-size-7xl: $font-size-base * 3;
$font-size-6xl: $font-size-base * 2.75;
$font-size-5xl: $font-size-base * 2.5;
$font-size-4xl: $font-size-base * 2.25;
$font-size-3xl: $font-size-base * 2;
$font-size-xxl: $font-size-base * 1.75;
$font-size-xl: $font-size-base * 1.5;
$font-size-lg: $font-size-base * 1.25;
$font-size-ml: $font-size-base * 1.125;
$font-size-md: $font-size-base * 0.9375;
$font-size-sm: $font-size-base * 0.875;
$font-size-xs: $font-size-base * 0.75;
$font-size-xxs: $font-size-base * 0.625;

$font-sizes: ();
$font-sizes: map-merge(("7xl": $font-size-7xl,
        "6xl": $font-size-6xl,
        "5xl": $font-size-5xl,
        "4xl": $font-size-4xl,
        "3xl": $font-size-3xl,
        "xxl": $font-size-xxl,
        "xl": $font-size-xl,
        "lg": $font-size-lg,
        "ml": $font-size-ml,
        "base": $font-size-base,
        "md": $font-size-md,
        "sm": $font-size-sm,
        "xs": $font-size-xs,
        "xxs": $font-size-xxs,
    ),
    $font-sizes);

@each $name,
$size in $font-sizes {

    .fs-#{$name} {
        font-size: $size !important;
    }

}

// Custom CSS

.fw-medium {
    font-weight: 500;
}

.fw-semi-bold {
    font-weight: 600;
}

.badge {
    text-transform: uppercase;
}

//found this hack here: https://stackoverflow.com/a/51349612/2633871
input[type="date"]::-webkit-calendar-picker-indicator {
    bottom: 0;
    background: transparent;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    z-index: 2;
}

.calender-icon {
    top: 5px;
    right: 10px;
    cursor: pointer;
    z-index: 1
}

.min-h-500 {
    /* 100vh - footer height */
    min-height: calc(100vh - 230px);
}

.cc-list {
    img {
        margin-left: auto;
        max-width: 28px;
        display: inline-block;
    }
}

.blockquote {
    padding: 0.75rem 1.25rem;
    border-left: 0.25rem solid #e9ecef;

    p {
        font-weight: 300;
    }
}


// Stripe Elements

.StripeElement {
    background-color: $input-bg;
    padding: $input-padding-y + .17 $input-padding-x;
    border-radius: $border-radius;
    border: 1px solid $input-border-color;
}

.StripeElement--focus {
    border-color: rgba(156, 163, 175, 1) !important;
    background-color: $input-focus-bg;
}

.StripeElement--invalid {
    border-color: $danger;
}

.InputElement::placeholder {
    color: #6c757d !important;
}

// Form Controls

.form-control {

    &:disabled,
    &.disabled,
    &[readonly] {
        background-color: $input-disabled-bg;
        border-color: $input-disabled-border-color;
        opacity: 1;
    }
}

.react-select.disabled {
    .react-select__control {
        background-color: $input-disabled-bg !important;
        border-color: $input-disabled-border-color;
        opacity: 1;
    }
}

// Flex Utilities
.flex-center {
    @extend .d-flex;
    @extend .flex-row;
    @extend .align-items-center;
}

.flex-center-between {
    @extend .flex-center;
    @extend .justify-content-between;
}

.flex-center-end {
    @extend .flex-center;
    @extend .justify-content-end;
}