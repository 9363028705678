// .donation-levels .custom-control {
//     padding-left: 0;
//     margin: auto 0.5rem;
//  padding-bottom: 1.5rem;
// }
.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.875rem;
    padding-left: 0;
    margin: auto 0.5rem;
    margin-bottom: 1.5rem;
}

.custom-control .custom-control-label .input-box-hint {
    display: -ms-flexbox;
    display: flex;
    font-size: 1.323rem;
    font-weight: 600;
}

.custom-select-flush,
.form-control-flush {
    padding-left: 0;
    border-color: transparent !important;
    background-color: transparent !important;
    resize: none;
}


.custom-control-input {
    position: absolute;
    left: 0;
    box-sizing: border-box;
    z-index: -1;
    width: 1.25rem;
    height: 1.375rem;
    opacity: 0;
}

.form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.custom-control-label {
    position: relative;
    color: #000;
    border: 1px solid #e9ecef;
    border-radius: 0.375rem;
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    text-align: center;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 114px;
}

.custom-control-label:hover {
    border-color: #000;
    transition: border .3s ease-in-out
}

input:checked+.custom-control-label {
    border-color: #000;
    color: #000;
    background-color: rgba(0, 0, 0, .02);
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.level-amount {
    font-size: 2.5rem;
    color: black;
}

.level-currency {
    font-size: 1.25rem;
    vertical-align: super;
    color: black;
}


.custom-control-label,
.custom-select {
    cursor: pointer !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.label {
    font-weight: 600;
}

.donation-selected {
    opacity: 1;
    width: 1.25rem;
    height: 1.25rem;
    background-color: #000 !important;
    border: none;
    border-radius: 50%;
    top: 0.5rem;
    right: 0.5rem;
    left: auto;
    bottom: auto;
    font-family: bootstrap-icons;
    content: "\f26e";
    font-size: .825rem;
    font-weight: 600;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    color: white;
}



input:checked+.custom-control-label:before {
    opacity: 1;
    width: 1.25rem;
    height: 1.25rem;
    background-color: #000 !important;
    border: none;
    border-radius: 50%;
    top: 0.5rem;
    right: 0.5rem;
    left: auto;
    bottom: auto;
    //  font-family: bootstrap-icons;
    content: "\2713";
    font-size: .825rem;
    font-weight: 600;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    color: white;

}

.custom-control-label:before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}